<template>
  <div>
    <base-page-heading title="Istoric comenzi">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Istoric comenzi</b-breadcrumb-item>
          <b-breadcrumb-item active>Lista</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <div class="content">
      <!-- Full Table -->
      <base-block title="Lista de comenzi" v-bind:subtitle="'Numar de comenzi: '+orderNumber">
        <b-table-simple responsive bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 70%;">Comanda</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 17%;">Creat de</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 18%;">Punct de lucru</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Transport</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Valoare</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%">TVA</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 13%;">Total</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 12%;">Data</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">INFO</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="orders.length <= 0 && loaded">
              <b-td class="d-md-none text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
              <b-td colspan="8" class="d-none d-sm-table-cell text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr v-for="order in orders" :key="order.node._id">
              <b-td class="d-sm-none" style="width: 15%;">
                <div><i class="fa fa-user-circle"></i> <span class="text-dark font-weight-bolder">{{ order.node.user.name }}</span></div>
                <div class="mt-2"><i class="fa fa-industry"></i> <span class="text-primary">{{ order.node.headquarter.name }}</span></div>
                <b-row>
                  <b-col class="col-6 mt-2">
                    <div><i class="fa fa-truck"></i> <span class="text-info">{{ order.node.transportPrice > 0 ? order.node.transportPrice.toFixed(2).toString() : '-' }} <span v-if="order.node.transportPrice > 0">RON</span></span></div>
                  </b-col>
                  <b-col class="col-6 mt-2">
                    <div><i class="fa fa-dollar-sign"></i> <span>{{ order.node.price.toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                  <b-col class="col-6 mt-2">
                    <div><i class="fa fa-percent"></i> <span class="text-warning">{{ (order.node.tax + order.node.transportTax).toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                  <b-col class="col-6 mt-2">
                    <div><i class="fa fa-money-bill-alt"></i> <span>{{ (order.node.grossPrice + order.node.transportTax + order.node.transportPrice).toFixed(2) }} <span>RON</span></span></div>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col class="col-6 mt-2">
                    <div><i class="fa fa-calendar-check"></i> <span>{{ getOrderDate(order.node.createdAt) }}</span></div>
                  </b-col>
                  <b-col class="col-6 mt-2 text-right">
                    <router-link class="align-items-center justify-content-between btn btn-alt-primary btn-sm"  v-bind:to="getDetailUrl(order.node.id)">
                      <i class="fa fa-fw fa-info-circle"></i> detalii
                    </router-link>
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-sm">
                <span class="text-dark font-weight-bolder">{{ order.node.user.name }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-primary">{{ order.node.headquarter.name }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center font-size-sm">
                <span class="text-info">{{ order.node.transportPrice > 0 ? order.node.transportPrice.toFixed(2).toString() : '-' }} <span v-if="order.node.transportPrice > 0" class="font-size-sm">RON</span></span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center font-size-sm">
                <span class="text-success">{{ order.node.price.toFixed(2) }} <span class="font-size-sm">RON</span></span>
              </b-td>
              <b-td class="border-right-0 d-none d-sm-table-cell text-center font-size-sm">
                <span class="text-warning">{{ (order.node.tax + order.node.transportTax).toFixed(2) }} <span class="font-size-sm">RON</span></span>
              </b-td>
              <b-td class="border-right-0 d-none d-sm-table-cell text-center font-size-sm">
                <span class="text-primary font-w600">{{ (order.node.grossPrice + order.node.transportTax + order.node.transportPrice).toFixed(2) }} <span class="font-size-sm">RON</span></span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span>{{ getOrderDate(order.node.createdAt) }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center border-left-0">
                <router-link class="align-items-center justify-content-between btn btn-alt-primary btn-md" v-bind:to="getDetailUrl(order.node.id)">
                  <i class="fa fa-fw fa-info-circle"></i> detalii
                </router-link>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
    <!-- END Page Content -->
    <infinite-loading @infinite="loadMore" ref="infiniteLoading">
      <div slot="spinner"><b-spinner variant="primary" label="Loading..."></b-spinner></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import {ITEM_NUMBER_PER_PAGE} from "../../../constants/settings";
import {fleshErrorMessage} from "../../../error/server-error";
import InfiniteLoading from "vue-infinite-loading";
import {ALL_ORDER_LIST} from "../../../constants/order-graphql";
import {getDateString} from "../../../constants/settings";

export default {
  name: "OrderList",
  components: {InfiniteLoading},
  data () {
    return {
      orders: [],
      pageInfo: null,
      orderNumber: 0,
      loaded: false,
    }
  },
  methods: {
    loadMore ($state) {
      this.$apollo.query({
        query: ALL_ORDER_LIST,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          after: (this.pageInfo) ? this.pageInfo.endCursor : null,
        },
        fetchPolicy: 'no-cache',
      }).then((result) => {
        this.pageInfo = result.data.meUser.company.orders.pageInfo;
        this.orders.push(...result.data.meUser.company.orders.edges);
        this.pageInfo.hasNextPage ? $state.loaded() : $state.complete();
        this.loaded = true;
        this.orderNumber = result.data.meUser.company.orders.totalCount;
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getDetailUrl(id)
    {
      return '/order/detail/'+btoa(encodeURI(id));
    },
    getOrderDate(date) {
      return getDateString(date)
    }
  }
}
</script>

<style scoped>

</style>